import { Divider, Typography, Spin ,Button, Empty} from "antd";
import { useContext, useEffect, useState ,useRef} from "react";
import { useParams } from "react-router-dom";
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, skip } from 'rxjs/operators';
import { CancelToken } from 'axios';

//Components
import InvoiceStatus from "./InvoiceStatus";
import EditPrice from "./EditPrice";
import EditProduct from "./EditProduct";
import EditCustomer from "./EditCustomer";

//Service
import {
    setInvoicePrice,
    setInvoiceProduct,
    setInvoiceWeight,
    setInvoiceQuantity,
    setInvoicingCustomer,
    getInvoiceItem
} from "../../services/InvoiceService";
import { ProductContext } from "../../services/ProductService/Provider";
import ProductProvider from "../../services/ProductService/Provider";
import ordersApi from "../../api/ordersApi";

//hooks
import useLocales from "../../hooks/useLocales";


export default function EditInvoicePage(){
    return <>
        <ProductProvider>
            <PageContent/>
        </ProductProvider>
    </>
}

function PageContent() {
  const {translate} = useLocales()
  const { Paragraph, Title } = Typography;
  const { products, isLoadingProduct, dataState : productState, getProductNameById } = useContext(ProductContext);
  const [saving, setSaving] = useState(false);
  const [invoiceCustomer, setInvoiceCustomer] = useState(null);
  const [product, setProduct] = useState(null);
  const [price, setPrice] = useState(null);
  const [dataState,setDataState] = useState("init");
  const [record,setRecord] = useState(null);
  const orderCustomer = useRef(null);

  const params = useParams()

  useEffect(()=>{
    setDataState("loading")
    initInvoiceItem()
    
    async function initInvoiceItem(){
      try {
        const invoiceItem = await getInvoiceItem(params.id);
        setRecord(invoiceItem)
        setInvoiceCustomer(invoiceItem.invoiceableCustomer)
        setProduct({
            productId: invoiceItem.productId,
            weight: invoiceItem.weighed && invoiceItem.weighed > 0 ? invoiceItem.weighed : invoiceItem.weight,
            quantity: invoiceItem.quantity,
          })
        setPrice({
            price: invoiceItem.price,
            discountPercentage: invoiceItem.discountPercentage,
            taxRate: invoiceItem.taxRate,
        })
        orderCustomer.current = invoiceItem.customer
        setProductChangeSubject(new BehaviorSubject({ productId: invoiceItem.productId, weight: invoiceItem.weighed ? invoiceItem.weighed : invoiceItem.weight }))
        setDataState("loaded")
      } catch (error) {
        setRecord(null)
        setDataState("loaded")
      }
    }

  },[])

  const [productChangeSubject, setProductChangeSubject] = useState(null);
  const [priceCancel, setPriceCancel] = useState(CancelToken.source());
  const [isCalculatingPrice, setIsCalculatingPrice] = useState(false);

  useEffect(() => {
    productChangeSubject && productChangeSubject.pipe(
      distinctUntilChanged((prev, curr) => {
        return prev.weight === curr.weight && prev.productId === curr.productId;
      }),
      skip(1),
      tap(_ => setIsCalculatingPrice(true)),
      debounceTime(1000))
      .subscribe(async a => {
        priceCancel.cancel();
        const token = CancelToken.source();
        setPriceCancel(token);
        const newPrice = await ordersApi.getPrice(record.orderItemId, a.productId, a.weight, token.token);
        handlePriceChange({
          price: newPrice,
          discountPercentage: price.discountPercentage,
          taxRate: price.taxRate
        });
        setIsCalculatingPrice(false)
      });
  }, [productChangeSubject])

  function handleProductChange(value) {
    setProduct({ ...value });
    productChangeSubject.next({ productId: value.productId, weight: value.weight });
  }

  function handlePriceChange(value) {
    setPrice({ ...value });
  }

  function handleInvoiceCustomerChange(value) {
    setInvoiceCustomer(value);
  }

  function checkInvalidInput() {
    if (
      (!product.weight && product.weight !=0) ||
      (!product.quantity && product.quantity!=0) ||
      (!price.price && price.price!=0) ||
      (!price.discountPercentage && price.discountPercentage !=0) ||
      (!price.taxRate && price.taxRate!=0)
    ) {
      return true;
    }
    return false;
  }

  async function handleSaveClick() {
    try {
      setSaving(true);
      await setInvoicingCustomer(record.loadItemId, invoiceCustomer.helosId);
      await setInvoiceProduct(record.loadItemId, product.productId);
      await setInvoiceWeight(record.loadItemId, product.weight);
      await setInvoiceQuantity(record.loadItemId, product.quantity);
      await setInvoicePrice(record.loadItemId, {
        price: price.price,
        discountPercentage: price.discountPercentage,
        taxPercentage: price.taxRate,
      });
      const newRecord = Object.assign({}, record);
      newRecord.invoiceableCustomer = invoiceCustomer;
      newRecord.productName = getProductNameById(product.productId);
      newRecord.weight = product.weight;
      newRecord.quantity = product.quantity;
      newRecord.price = price.price;
      newRecord.discountPercentage = price.discountPercentage;
      newRecord.taxRate = price.taxRate;
    } catch (error) {
    }
    setSaving(false);
  }

  return (
    <>
      {
        (dataState == "loaded" && productState == "loaded") ? (record ? (<div style={{width:800,margin:"20px auto 0 auto",padding:50,border:"1px solid 	#C8C8C8"}}>
          <div className="titleContainer">
            <strong>{translate("EditInvoice_Header_Detail")} &nbsp;</strong>
            <strong style={{ color: "#1976D2" }}>{record.loadId}</strong>

            <Typography style={{ marginLeft: "8px", fontSize: "10px" }} type="secondary"> ({record.loadItemId})</Typography>
          </div>
          <InvoiceStatus record={record} />
          <EditCustomer
            orderCustomer={orderCustomer.current}
            invoiceCustomer={invoiceCustomer}
            onInvoiceCustomerChange={handleInvoiceCustomerChange}
          />
          <EditProduct
            isLoadingProduct={isLoadingProduct}
            listProduct={products}
            product={product}
            onProductChange={handleProductChange}
          />
          <div className="priceCalculationSpinner">
            {
              isCalculatingPrice ?
                <Spin tip="Calculating price..." /> :
                <EditPrice priceValue={price} onPriceChange={handlePriceChange} quantity={product.quantity} />
            }
          </div>

          {record.invoicingReference ?
            <Typography>
              <Divider />
              <Title level={5}>{translate("EditInvoice_Reference")}</Title>
              <Paragraph italic>{record.invoicingReference}</Paragraph>
            </Typography>
            : ""}
          <Divider/>
          <div style={{display:"flex", justifyContent:"flex-end"}}>
            <Button 
              type="primary"
              loading={saving}
              onClick={handleSaveClick}
              disabled={checkInvalidInput()}
              >
              {translate("Common_Save")}
            </Button>
          </div>
        </div>) : 
        (
          <Empty/>
        )

        ) :
        <div style={{marginTop :"100px" , display:"flex" , justifyContent:"center"}}>
          <Spin size="large"/>
        </div>
      }
    </>
  );
}

