
import { Select , Skeleton} from "antd";
import clsx from "clsx";
import "./style.scss"

export default function CustomSelect({children,label,isLoading=false,...props}) {
    const {className,style,...rest} = props
  return (
    <div className={clsx("inputContainer",className)} style={style}>
    {label && <strong>{label}</strong>}
    <Select
        className="selectorContainer"
        {...rest}
      dropdownRender={(menu) => {
        return isLoading ? (
          <>
            <Skeleton
              active
              title={false}
            />
            <Skeleton
              active
              title={false}
            />
            <Skeleton
              active
              title={false}
            />
            <Skeleton
              active
              title={false}
            />
            <Skeleton
              active
              title={false}
            />
      
          </>
        ) : (
          <>{menu}</>
        );
      }}
    >
      {children}
    </Select>
  </div>
  );
}
