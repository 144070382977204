import { useState, useEffect } from "react";
// import { TextField, CurrencyInput} from "../commons";
import CurrencyInput, { formatValue } from "react-currency-input-field";
import { Typography, InputNumber, Divider } from "antd";
import useLocales from "../../hooks/useLocales";
const { Text } = Typography;

export default function EditPrice({ priceValue, onPriceChange, quantity, allowMany, isReadOnly }) {
  const [price, setPrice] = useState(priceValue.price);
  const [discount, setDisCount] = useState(priceValue.discountPercentage);
  const [taxRate, setTaxRate] = useState(priceValue.taxRate);
  const [qty, setQuantity] = useState(quantity);
  const [isManyAllowed, setIsManyAllowed] = useState(allowMany);

  const { translate } = useLocales()

  const priceNumber =
    price + "" ? parseFloat((price + "").replace(",", ".")) : 0;
  const discountNumber = parseFloat(discount);
  const taxRateNumber = parseFloat(taxRate);
  const NetPrice = (priceNumber * ((100 - discountNumber) / 100)).toFixed(2);
  const TotalPrice = (NetPrice * ((100 + taxRateNumber) / 100)).toFixed(2);
  const Tax = (TotalPrice - NetPrice).toFixed(2);

  const formatedNetPrice = formatValue({
    value: (isManyAllowed ? (qty * NetPrice) : NetPrice) + "",
    groupSeparator: ".",
    decimalSeparator: ",",
  });
  const formatedTotalPrice = formatValue({
    value: (isManyAllowed ? (qty * TotalPrice) : TotalPrice) + "",
    groupSeparator: ".",
    decimalSeparator: ",",
  });

  const formatedTax = formatValue({
    value: (isManyAllowed ? (qty * Tax) : Tax) + "",
    groupSeparator: ".",
    decimalSeparator: ",",
  });

  function handlePriceChange(value) {
    setPrice(value);
    onPriceChange &&
      onPriceChange({
        price: parseFloat((value + "").replace(",", ".")),
        discountPercentage: discount,
        taxRate: taxRate,
      });
  }
  function handleDiscountChange(value) {
    const number = parseFloat(value);
    if (number && number > 100) {
      return;
    }
    setDisCount(value);
    onPriceChange &&
      onPriceChange({
        price: parseFloat((price + "").replace(",", ".")),
        discountPercentage: value,
        taxRate: taxRate,
      });
  }
  function handleTaxRateChange(value) {
    setTaxRate(value);
    onPriceChange &&
      onPriceChange({
        price: parseFloat((price + "").replace(",", ".")),
        discountPercentage: discount,
        taxRate: value,
      });
  }

  useEffect(() => {
    setPrice(priceValue.price);
  }, [priceValue]);

  useEffect(() => {
    setQuantity(quantity);
  }, [quantity]);

  useEffect(() => {
    setIsManyAllowed(allowMany);
  }, [allowMany]);

  return (
    <>
      <div className="priceEditContainer">
        <div className="inputContainer" style={{ width: "150px" }}>
          <strong>{translate("EditInvoice_Price_Quantity")}</strong>
          <CurrencyInput
            className="inputTextField"
            disabled={isReadOnly}
            allowNegativeValue={false}
            decimalSeparator=","
            groupSeparator="."
            onValueChange={handlePriceChange}
            defaultValue={price}
            value={price}
          />
        </div>
        <div className="inputContainer" style={{ width: "75px" }}>
          <strong>{translate("EditInvoice_Price_Discount")}</strong>
          <CurrencyInput disabled={isReadOnly}
            className="inputTextField"
            allowNegativeValue={false}
            suffix="%"
            disableGroupSeparators={true}
            onValueChange={handleDiscountChange}
            defaultValue={discount}
            value={discount}
          />
        </div>
        <div className="inputContainer" style={{ width: "75px" }}>
          <strong>Tax</strong>
          <CurrencyInput disabled={isReadOnly}
            className="inputTextField"
            allowNegativeValue={false}
            suffix="%"
            disableGroupSeparators={true}
            onValueChange={handleTaxRateChange}
            defaultValue={taxRate}
            value={taxRate}
          />
        </div>
        <div className="priceContainer">
          <strong>{translate("EditInvoice_Price_Total")}</strong>
          <strong>{formatedNetPrice}</strong>
          <strong>{translate("EditInvoice_Price_Tax")} {formatedTax}</strong>
          <strong style={{ color: "#1976D2" }}>{formatedTotalPrice}</strong>
        </div>
      </div>
    </>
  );
}
