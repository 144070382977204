import { useTranslation } from 'react-i18next';
import { allLangs, defaultLang } from '../config';

// ----------------------------------------------------------------------

export default function useLocales() {
  const {t: translate } = useTranslation();
  return {
    translate: (text, options) => translate(text, options),
  };
}
