import { InvoiceStatus } from "../../const";
import invoiceApi from "../../api/invoiceApi";


export async function fetchDataByFilter(filter){
    try {
        const { data: invoices } = await invoiceApi.getList({
            ...filter,
          });
          const listData = invoices.items.map((value) => {
            return { ...value, key: value.loadItemId};
          });
          return {
              listInvoices:listData,
              totalInvoices:invoices.totalCount,
              currentPage:invoices.pageIndex
          }
    } catch (error) {
        throw new Error(error)
    }
  };
  

  export async function setItemState(loadItemId, state) {
    try {
      switch (state) {
        case InvoiceStatus.Flagged:
          await invoiceApi.putFlag(loadItemId);
          break;
        case InvoiceStatus.ToBeInvoiced:
          await invoiceApi.putToBeInvoice(loadItemId);
          break;
        case InvoiceStatus.Open:
          await invoiceApi.putOpen(loadItemId);
          break;
        case InvoiceStatus.NotToBeInvoiced:
          await invoiceApi.putRemove(loadItemId);
          break;

        default:
          break;
      }
    } catch (error) {
        throw new Error(error)
    }
  }

  export async function downLoadBatch(batchId) {
    try {
        const response = await invoiceApi.downloadBatch(batchId);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Batch${batchId}.csv`);
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        throw new Error(error)
    }
  };

  export async function downLoadPreviewBatch(filter) {
    try {
        const response = await invoiceApi.downloadPreviewBatch(filter);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Batch-Preview.csv`);
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        throw new Error(error)
    }
  };
export async function setInvoicePrice(id,price){
  try {
    const response = await invoiceApi.setInvoicePrice(id,price);
  } catch (error) {
    throw new Error(error)
  }
}
export async function setInvoiceProduct(id,productId){
  try {
    const response = await invoiceApi.setInvoiceProduct(id,productId);
  } catch (error) {
    throw new Error(error)
  }
}
export async function setInvoiceQuantity(id,quantity){
  try {
    const response = await invoiceApi.setInvoiceQuantity(id,quantity);
  } catch (error) {
    throw new Error(error)
  }
}
export async function setInvoiceWeight(id,weight){
  try {
    const response = await invoiceApi.setInvoiceWeight(id,weight);
  } catch (error) {
    throw new Error(error)
  }
}
export async function setInvoicingCustomer(id,customerId){
  try {
    const response = await invoiceApi.setInvoicingCustomer(id,customerId);
  } catch (error) {
    throw new Error(error)
  }
}
export async function getInvoiceItem(id){
  try {
    const response = await invoiceApi.getInvoiceItem(id);
    return response.data;
  } catch (error) {
    throw new Error(error)
  }
}