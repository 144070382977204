import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "antd";
import { FileTextOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import useLocales from "../../hooks/useLocales";

//components
import { DatePicker } from "../../components";

//ap
import invoiceApi from "../../api/invoiceApi";

export default function CreateBatchModal({ untilDate, records ,disabled}) {
  const [totalCount, setTotalCount] = useState(0);
  const [visible, setVisible] = useState(false);
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [untilDateState, setUntilDate] = useState(untilDate);
  const batchIdRef = useRef("");
  const {translate} = useLocales()

  useEffect(() => {
    setUntilDate(untilDate);
  }, [untilDate]);

  useEffect(() => {
    getSelectedInvoice();
  }, [untilDateState, records]);

  const onUntilDateChange = (date) => {
    const apiFormatedDate = dayjs(date).toISOString();
    setUntilDate(apiFormatedDate);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleDownloadClick = () => {
    downLoadBatch();
    handleClose();
  };

  const handleOk = () => {
    setConfirmLoading(true);
    handleCreatingBatch();
  };
  const handleClose = () => {
    setDownloadModalVisible(false);
  };

  async function downLoadBatch() {
    const response = await invoiceApi.downloadBatch(batchIdRef.current);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "batch.csv"); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  async function getSelectedInvoice() {
    try {
      const { data: response } = await invoiceApi.getSelectedCount(untilDateState);
      setTotalCount(response);
    } catch (error) {}
  }

  async function handleCreatingBatch() {
    try {
      var params = {
        until: untilDateState,
      };
      const { data: response } = await invoiceApi.createBatch(params);
      batchIdRef.current = response;
      setDownloadModalVisible(true);
    } catch (error) {}
    setVisible(false);
    setConfirmLoading(false);
  }

  return (
    <>
      <Button 
        type="primary" 
        className="main-button" 
        onClick={showModal}
        disabled={disabled}
      >
        {translate("Invoices_Batch_BtnTitle")} : {totalCount}
      </Button>

      <Modal
        title={
          <>
            <div className="titleModal">
              <FileTextOutlined />
              <h2>{translate("Invoices_Title")}</h2>
            </div>
          </>
        }
        visible={visible}
        onCancel={handleCancel}
        onOk={handleOk}
        footer={[
          <Button key="cancle" onClick={handleCancel}>
            {translate("Common_Cancel")}
          </Button>,
          <Button
            key="confirm"
            type="primary"
            loading={confirmLoading}
            onClick={handleOk}
          >
            {translate("Common_Confirm")}
          </Button>,
        ]}
      >
        <div className="contentModal">
          <p>
            <strong>{totalCount}</strong> {translate("Invoices_Batch_Description")}
          </p>
          <div className="dataPicker">
            <span className="label">{translate("Invoices_Batch_SelectDate")}</span>
            <DatePicker
              allowClear={false}
              format={"D.M.YYYY"}
              value={dayjs(untilDateState)}
              defaultValue={dayjs(untilDateState)}
              onChange={(date) => {
                onUntilDateChange(date);
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        title={
          <>
            <div className="titleModal">
              <FileTextOutlined />
              <h2>{translate("Batch")} {batchIdRef.current}</h2>
            </div>
          </>
        }
        footer={[
          <Button key="download" onClick={handleDownloadClick}>
            {translate("Common_Download")}
          </Button>,
          <Button key="close" type="primary" onClick={handleClose}>
            {translate("Common_Close")}
          </Button>,
        ]}
        visible={downloadModalVisible}
        onCancel={handleClose}
      >
        <p></p>
      </Modal>
    </>
  );
}
