
import { Routes , Route , useLocation } from "react-router-dom"

//guard
import UserRoleGuard from "../guard/UserRoleGuard"

//pages
import InvoicesPage from '../pages/InvoicesPage'
import EditInvoicePage from "../pages/EditInvoicePage"



export default function AppRoutes(){
    const location = useLocation()
    const backgroundLocation =  location?.state?.backgroundLocation
    return (<>
        <Routes location={backgroundLocation || location}>
            <Route 
                path="/" 
                element={<UserRoleGuard><InvoicesPage/></UserRoleGuard>} 
            />
            <Route 
                path="/invoiceItem/:id/edit" 
                element={<UserRoleGuard><EditInvoicePage/></UserRoleGuard>} 
            />
        </Routes>
    </>)
}