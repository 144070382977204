import { InputNumber } from "antd";
import clsx from "clsx";
import "./style.scss"

export default function CustomInputNumber(props) {
  const {className,style,label,...rest} =props
  return (
    <>
      <div className={clsx("inputContainer",className)} style={style}>
        {label && <strong>{label}</strong>}
        <InputNumber
          className="inputNumberContainer"
          {...rest}
        />
      </div>
    </>
  );
}
