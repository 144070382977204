import axios from 'axios';
import queryString from 'query-string'
import { authService } from '../SecuredApp';
const URL_GATEWAY = `${process.env.REACT_APP_HELOS_API}`;
axios.defaults.baseURL = URL_GATEWAY;
axios.defaults.timeout = 0;
axios.defaults.headers = {
  'content-type': 'application/json',
}
axios.defaults.paramsSerializer = params => queryString.stringify(params)



const loadingStatusSubscribers = [];
function publishLoadingStatus(status) {
  loadingStatusSubscribers.forEach((callback) => {
    if (typeof callback === 'function') {
      callback(status);
    }
  });
}

export function subscribeToLoadingStatus(callback) {
  loadingStatusSubscribers.push(callback);
}

axios.interceptors.request.use((request) => {
  if (localStorage.getItem('token')) {
    const token = JSON.parse(localStorage.getItem('token'))
    request.headers.Authorization = `Bearer ${token.access_token}`;
  } else {
    delete request.headers.Authorization;
  }
  return request;
});

let refreshPromise = null;

const errHandler = async (error) => {
  publishLoadingStatus(false);
  const config = error.config;
  if (error.response && error.response.status === 413) throw new Error(`Attachments cannot be more than ${process.env.FILE_SIZE_SCAN}mb.`);
  if (error.response && error.response.status === 401 && !config._retry) {
    config._retry = true;
    if (refreshPromise == null) {
      const refreshToken = JSON.parse(localStorage.getItem('token')).refresh_token;
      refreshPromise = authService.fetchToken(refreshToken, true)
        .then(token => {
          try {
            // if the token is not valid (invalid_grant) then .getUser() call fails
            // in this case, force user to reload so the "App" is unloaded.
            const user = authService.getUser();
            localStorage.setItem('token', JSON.stringify(token))
            localStorage.setItem('user', JSON.stringify(user));
            refreshPromise = null;
            return axios(config)
          }
          catch (err) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');                       
            refreshPromise = null;
            window.location.reload(false);
          }
        }).catch(error => {
          console.log(error);
        });
      return await refreshPromise;

    } else {
      await refreshPromise;
      return axios(config)
    }

  }
  throw error;
};

axios.interceptors.request.use(async cfg => {
  if (refreshPromise) {
    await refreshPromise;
  }
  return cfg;
});

axios.interceptors.response.use(
  (response) => {
    publishLoadingStatus(false);
    return response;
  },
  (error) => errHandler(error),
);

export default axios;
