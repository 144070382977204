import { Divider, Button } from "antd";
import { useState } from "react";
import { EditFilled, CloseOutlined } from "@ant-design/icons";
import SelectCustomer from "./SelectCustomer";
import "./style.scss";
import useLocales from "../../../hooks/useLocales";
import { use } from "i18next";

export default function EditCustomer({
  orderCustomer,
  invoiceCustomer,
  onInvoiceCustomerChange,
  isReadOnly
}) {
  const [showEditCustomer, setShowEditCustomer] = useState(false);
  const { translate } = useLocales()

  function handleSelectChange(value) {
    setShowEditCustomer(false)
    onInvoiceCustomerChange && onInvoiceCustomerChange(value)
  }

  function getPickAddress() {
    let address = orderCustomer.addresses.find(a => a.isPickAddress);
    if (!address) {
      return orderCustomer.addresses.find(() => true);
    }
    return address;
  }

  function getInvoicingAddress() {
    let address = invoiceCustomer.addresses.find(a => a.isInvoicingAddress);
    if (!address) {
      return invoiceCustomer.addresses.find(() => true);
    }
    return address;
  }

  return (
    <>
      <div className="editCustomerContainer">
        <div className="orderCustomerContainer">
          <strong>{translate("EditInvoice_Customer_Order")}</strong>
          <div style={{ marginTop: "10px" }}>
            {orderCustomer.name}
          </div>
          <div>{getPickAddress()?.address ?? ''}</div>
          <div>{getPickAddress()?.postNumber ?? ''} {getPickAddress()?.postOffice ?? ''}</div>
        </div>
        <div className="invoiceCustomerContainer">
          <div className="header">
            <strong>{translate("EditInvoice_Customer_Invoice")}</strong>
            {showEditCustomer ? (
              <Button size="small" type="text" icon={<CloseOutlined />} onClick={() => { setShowEditCustomer(false) }} />
            ) : (
              <Button size="small" disabled={isReadOnly} type="text" icon={<EditFilled />} onClick={() => { setShowEditCustomer(true) }} />
            )}
          </div>
          <div className="content">
            {showEditCustomer ? (
              <SelectCustomer
                onChange={handleSelectChange}
              />
            ) : (
              <>
                <div style={{ marginTop: "10px" }}>
                  {invoiceCustomer.name}
                </div>
                <div>{getInvoicingAddress().address}</div>
                <div>{getInvoicingAddress().postNumber} {getInvoicingAddress().postOffice}</div>
              </>
            )}
          </div>
        </div>
      </div>
      <Divider />
    </>
  );
}
