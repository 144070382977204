import { createContext, useState, useEffect } from "react";
import { getProductList } from ".";

const ProductContext = createContext()

export default function ProductProvider({ children }) {
    const [productList, setProductList] = useState();
    const [isLoadingProduct, setLoadingProduct] = useState(false)
    const [dataState,setDataState] = useState("init")
    useEffect(() => {
        getProductsList();
    }, []);

    async function getProductsList() {
        setDataState("loading")
        setLoadingProduct(true);
        try {
            const productList = await getProductList();
            setProductList(productList);
        } catch (error) {
            console.log(error)
        }
        setDataState("loaded")
        setLoadingProduct(false);
    }

    function getProductIdByName(name) {
        const foundProduct = productList.find((item) => item.name === name)
        return foundProduct ? foundProduct.id : null
    }

    function getProductNameById(id) {
        const foundProduct = productList.find((item) => item.id === id)
        return foundProduct ? foundProduct.name : null
    }

    return (<ProductContext.Provider value={{
        products: productList,
        isLoadingProduct: isLoadingProduct,
        dataState,
        getProductIdByName,
        getProductNameById
    }}>
        {children}
    </ProductContext.Provider>)
}

export { ProductContext }