import React, { useEffect } from "react";
import "antd/dist/antd.min.css";
import "./assets/style.scss";
import connection from "./services/signalRClient/InvoicingConnection";
import Routes from "./routes";

function App() {
  useEffect(() => {
    async function start() {
      try {
        await connection.start();
        console.log("SignalR Connected.");
      } catch (err) {
        console.log(err);
        setTimeout(start, 5000);
      }
    }
    start();
    return () => {
      async function stop() {
        try {
          await connection.stop();
          console.log("SignalR Disconnected.");
        } catch (err) {
          console.log(err);
          setTimeout(stop, 5000);
        }
      }
      stop();
    };
  }, []);
  return (
    <>
      <Routes/>
    </>
  );
}

export default App;
