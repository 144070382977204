

import { HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";

const connection = new HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_SIGNALR_HUB, {
        accessTokenFactory: () => {
          if (localStorage.getItem('token')) {
              const token = JSON.parse(localStorage.getItem('token'))
            return `${token.access_token}`;
          }
          return "";
        },
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true
      })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build();

export default connection