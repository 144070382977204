import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { Row, Col } from "antd";

import useLocales from "../../hooks/useLocales";


export default function ErrorNotification({
    message,
    onClick
}) {
    const { translate } = useLocales()

    return (
        <>
            <div onClick={onClick}>
                <Row>
                    <Col flex="auto">
                        <ExclamationCircleTwoTone style={{ fontSize: '24px' }} twoToneColor="#eb2f96" /></Col>
                    <Col flex="auto">
                        <p>{message}</p></Col>
                </Row>
            </div>
        </>
    );
}
