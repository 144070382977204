import axios from './axiosClient'

const ordersApi = {
    getPrice: async (orderItemId, productId, weight, cancelToken) => {

        var params = {
            productId: productId,
            weight: weight
        };

        const url = '/Orders/item/' + orderItemId + '/price'
        const response = await axios.get(url,
            { params }
            , { cancelToken: cancelToken });
        return response.data;
    },
}

export default ordersApi;