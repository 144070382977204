//format : page_component_description

const en = {
  //Locacle for common
  Common_Save:"Save",
  Common_Confirm:"Confirm",
  Common_Download:"Download",
  Common_Cancel:"Cancel",
  Common_Close:"Close",
  Common_Revert:"Revert",

  Common_Search:"Search",
  Common_From:"From",
  Common_Until:"To",
  Common_Today:"Today",
  Common_ThisMonth:"This Month",
  Common_Last2Weeks:"Last 2 weeks",
  Common_Depot:"Depot",

  Common_Status:"Status",
  Common_All:"All",
  Common_Flagged:"Flagged",
  Common_ToBeInvoiced:"To Be Invoiced",
  Common_Invoiced:"Invoiced",
  Common_Open:"Open",
  Common_Invoice:"Invoice",

  //Locale invoices page
  Invoices_Title:"Cadaver Invoicing",
  Invoices_Table_Load:"Load",
  Invoices_Table_Customer:"Customer",
  Invoices_Table_ExternalID:"Ext. ID",
  Invoices_Table_Delivery:"Delivery",
  Invoices_Table_Product:"Product",
  Invoices_Table_Weight:"Weight",
  Invoices_Table_Price:"Price",
  Invoices_Table_InvoicingBatch:"Invoicing Batch",
  Invoices_Filter_Preview:"Export Preview",
  
  Invoices_Pagination_Range:"%1 of %2 items",

  Invoices_Batch_BtnTitle:"Selected Invoices",
  Invoices_Batch_Description:"invoices selected for batch",
  Invoices_Batch_InvoiceBatch:"Invoice Batch",
  Invoices_Batch_SelectDate:"Select Date",
  Invoices_Batch_BatchIndex:"Batch",

  Invoices_Dropdown_Flag:"Flag",
  Invoices_Dropdown_Remove:"Remove",
  Invoices_Dropdown_Edit:"Edit",
  Invoices_Dropdown_UnFlag:"UnFlag",
  Invoices_Dropdown_DeleteInvoice:"Delete Invoice",
  Invoices_Dropdown_DeleteInvoice_Confirm:"Please confirm that the selected invoice will be permanently removed...",
  Invoices_Dropdown_Created:"Created",
  Invoices_Dropdown_CreatedBy:"Created by",

  //Locale for edit page
  EditInvoice_Header_Detail:"Detail",

  EditInvoice_Status_Ordered:"Ordered",
  EditInvoice_Status_Picked:"Picked",
  EditInvoice_Status_Delivered:"Delivered",

  EditInvoice_Customer_Order:"Order Customer",
  EditInvoice_Customer_Invoice:"Invoice Customer",
  EditInvoice_Customer_Select:"Select Customer",

  EditInvoice_Product_Name:"Product Name",
  EditInvoice_Product_Weight:"Weight",
  EditInvoice_Product_Quantity:"Quantity",

  EditInvoice_Price_Quantity:"Price €",
  EditInvoice_Price_Tax:"Tax",
  EditInvoice_Price_Discount:"Discount",
  EditInvoice_Price_Total:"Total €",
  
  EditInvoice_Reference:"Invoicing reference",
  EditInvoice_NoValidPriceFound:"No valid price found for the product during the time of order"
}

export default en;