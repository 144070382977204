import React, { useContext, useRef, useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  MoreOutlined,
  DeleteOutlined,
  CloseOutlined,
  FlagOutlined,
  EditFilled,
  DownloadOutlined,
  FileDoneOutlined
} from "@ant-design/icons";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Popover, Dropdown, Menu } from "antd";

import { InvoiceStatus } from "../../const";
import { stateInvoicesContext } from "./";
import { downLoadBatch } from "../../services/InvoiceService";

//hooks
import useLocales from "../../hooks/useLocales";

export default function MoreActionDropDown({ record }) {
  const [isShowConfirmToast, setShowConfirmToast] = React.useState(false);
  const { translate } = useLocales()
  const [toastVisible, setToastVisible] = React.useState(false);
  const { loadItemId, status } = record;
  const { changeState } = useContext(stateInvoicesContext);
  const [searchParams, setSearchParams] = useSearchParams()

  function handleClickEdit() {
    setSearchParams({
      editingItem: loadItemId,
      modal: "open"
    })
  }

  function handleDeleteInvoiceClick() {
    setShowConfirmToast(false);
    changeState(loadItemId, InvoiceStatus.NotToBeInvoiced, status);
  }

  function handleMenuClick(item) {
    setToastVisible(false);
    switch (item.key) {
      case "1":
        if (
          status === InvoiceStatus.ToBeInvoiced ||
          status === InvoiceStatus.Open
        ) {
          changeState(loadItemId, InvoiceStatus.Flagged, status);
        } else if (status === InvoiceStatus.Flagged) {
          changeState(loadItemId, InvoiceStatus.Open, status);
        }
        break;
      case "2":
        setShowConfirmToast(true);
        break;
      case "3":
        handleClickEdit()
        break;

      default:
        break;
    }
  }

  function handleDownloadClick(batchId) {
    setToastVisible(false);
    downLoadBatch(batchId);
  }

  const titleDelete = () => {
    return (
      <>
        <div className="toastTitle">
          <div className="titleText">{translate("Invoices_Dropdown_DeleteInvoice")}</div>
          <div
            className="titleRemoveIcon"
            onClick={() => {
              setShowConfirmToast(false);
            }}
          >
            <CloseOutlined />
          </div>
        </div>
      </>
    );
  };

  const titleDownload = () => {
    return (
      <>
        <div className="toastTitle">
          <div className="titleText">Batch {record.batchId}</div>
          <div
            className="titleRemoveIcon"
            onClick={() => {
              setToastVisible(false);
            }}
          >
            <CloseOutlined />
          </div>
        </div>
      </>
    );
  };

  const contentDelete = () => {
    return (
      <>
        <div className="toastContent">
          <p>
            {translate("Invoices_Dropdown_DeleteInvoice_Confirm")}
          </p>
          <Button type="primary" onClick={handleDeleteInvoiceClick}>
            {translate("Invoices_Dropdown_DeleteInvoice")}
          </Button>
        </div>
      </>
    );
  };

  const contentDownload = () => {
    return (
      <>
        <div className="toastContent">
        <Button icon={<FileDoneOutlined />}
            type="dashed" shape="circle"
            onClick={() => {
              handleMenuClick({key:"3"});
            }}
          >
          </Button>
          <p>{translate("Invoices_Dropdown_Created")}: {dayjs(record.invoiced).format("D.M.YYYY")}</p>
          <p>{translate("Invoices_Dropdown_CreatedBy")}: {record.invoicingUserName}</p>
          <Button icon={<DownloadOutlined />}
            type="primary"
            disabled={!record.batchId}
            onClick={() => {
              handleDownloadClick(record.batchId);
            }}
          >
            {translate("Common_Download")}
          </Button>
        </div>
      </>
    );
  };

  const menu = () => {
    switch (status) {
      case InvoiceStatus.Flagged:
        return (
          <Menu
            items={[
              {
                label: translate("Invoices_Dropdown_UnFlag"),
                key: "1",
                icon: <FlagOutlined />,
              },
              {
                label: translate("Invoices_Dropdown_Remove"),
                key: "2",
                icon: <DeleteOutlined />,
              },
              {
                label: translate("Invoices_Dropdown_Edit"),
                key: "3",
                icon: <EditFilled />,
              },
            ]}
            onClick={handleMenuClick}
          />
        );
        break;
      case InvoiceStatus.ToBeInvoiced:
      case InvoiceStatus.Open:
        return (
          <Menu
            items={[
              {
                label: translate("Invoices_Dropdown_Flag"),
                key: "1",
                icon: <FlagOutlined />,
              },
              {
                label: translate("Invoices_Dropdown_Remove"),
                key: "2",
                icon: <DeleteOutlined />,
              },
              {
                label: translate("Invoices_Dropdown_Edit"),
                key: "3",
                icon: <EditFilled />,
              },
            ]}
            onClick={handleMenuClick}
          />
        );
        break;
      default:
        return null;
    }
  };

  return (
    <>
      {!isShowConfirmToast ? (
        status === InvoiceStatus.Invoiced ? (
          <Popover
            trigger="click"
            title={titleDownload}
            content={contentDownload}
            placement="bottomRight"
            overlayClassName="invoicingMoreActionToast"
            visible={toastVisible}
            onVisibleChange={(visible) => {
              setToastVisible(visible);
            }}
          >
            <div
              onClick={() => {
                setToastVisible(true);
              }}
            >
              <MoreOutlined className="action-icon" />
            </div>
          </Popover>
        ) : (
          <Dropdown
            trigger="click"
            visible={toastVisible}
            overlay={menu}
            onVisibleChange={(visible) => {
              setToastVisible(visible);
            }}
            placement="bottomRight"
          >
            <div
              onClick={() => {
                setToastVisible(true);
              }}
            >
              <MoreOutlined className="action-icon" />
            </div>
          </Dropdown>
        )
      ) : (
        <Popover
          trigger="click"
          title={titleDelete}
          content={contentDelete}
          placement="bottomRight"
          overlayClassName="invoicingMoreActionToast"
          visible={isShowConfirmToast}
          onVisibleChange={(visible) => {
            setShowConfirmToast(false);
          }}
        >
          <div
            onClick={() => {
              setToastVisible(true);
            }}
          >
            <MoreOutlined className="action-icon" />
          </div>
        </Popover>
      )}
    </>
  );
}
