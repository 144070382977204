import React, { createContext, useEffect, useRef, useState } from "react";
import { FileTextOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Pagination, notification, Spin } from "antd";
import dayjs from "dayjs";

import { InvoiceStatus } from "../../const";
import CreateBatchModal from "./CreatingBatchModal";
import InvoicingConnection from "../../services/signalRClient/InvoicingConnection";
import InvoicesFilter from "./InvoicesFilter";
import InvoicesTable from "./InvoicesTable";
import { fetchDataByFilter, setItemState, downLoadPreviewBatch } from "../../services/InvoiceService";
import StateChangeNotification from "./StateChangeNotification";
import ProductProvider from "../../services/ProductService/Provider";
import DepotProvider from "../../services/DepotService/DepotProvider";

import useLocales from "../../hooks/useLocales";
import stringArgReplace from "../../utils/stringArgReplace";
import EditInvoiceModal from "./EditInvoiceModal";

const defaultCurrentPage = 1;
const defaultPageSize = 20;

const stateInvoicesContext = createContext();

export default function InvoicesMainPage() {
  const { translate } = useLocales();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [pageSize, setCurrentPageSize] = useState(defaultPageSize);
  const [changingItem, setChangingItem] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isCheckingAll, setCheckingAll] = useState(false);

  var totalCount = useRef(0);

  // Handle Filter logic
  const [filter, setFilter] = useState(() => {
    var localFilter = JSON.parse(localStorage.getItem("filter"));
    if (localFilter) {
      return localFilter;
    } else {
      localFilter = {
        searchTerm: "",
        type: InvoiceStatus.Open,
        from: dayjs().subtract(14, "day").toISOString(),
        to: dayjs().toISOString(),
        depot: [],
      };
      return localFilter;
    }
  });

  const isFirstMounted = useRef(true);
  useEffect(() => {
    if (isFirstMounted.current) {
      isFirstMounted.current = false;
    } else {
      var localFilter = { ...filter };
      localStorage.setItem("filter", JSON.stringify(localFilter));
    }
  }, [filter]);

  // Listen SignalR for invoice changing
  const handleStateChanged = (loadItemId, state) => {
    setData((currentData) => {
      var matchIndex = currentData.findIndex((value) => {
        return value.loadItemId === loadItemId;
      });
      const newData = currentData.map((value) => Object.assign({}, value));
      if (matchIndex >= 0) {
        if (state === InvoiceStatus.NotToBeInvoiced)
          newData.splice(matchIndex, 1);
        else newData[matchIndex].status = state;
      }
      return newData;
    });
  };

  const handleInvoiced = (items, batchId) => {
    setData((currentData) => {
      const newData = currentData.map((value) => Object.assign({}, value));
      items.forEach((loadItemId) => {
        let matchIndex = currentData.findIndex((value) => {
          return value.loadItemId === loadItemId;
        });
        if (matchIndex !== -1) {
          newData[matchIndex].batchId = batchId;
          newData[matchIndex].status = InvoiceStatus.Invoiced;
        }
      });
      return newData;
    });
  };

  const handlePropertyUpdated = (loadItemId, propertyName, newValue) => {
    setData((currentData) => {
      var matchIndex = currentData.findIndex((value) => {
        return value.loadItemId === loadItemId;
      });
      const newData = currentData.map((value) => Object.assign({}, value));
      if (matchIndex >= 0) {
        newData[matchIndex][propertyName] = newValue;
      }
      return newData;
    });
  };

  useEffect(() => {
    InvoicingConnection.on("StateChanged", handleStateChanged);
    InvoicingConnection.on("Invoiced", handleInvoiced);
    InvoicingConnection.on("Updated", handlePropertyUpdated);
    return () => {
      InvoicingConnection.off();
    };
  }, []);

  function handleInvoiceInfoChange(loadItemId, record) {
    setData((currentData) => {
      var matchIndex = currentData.findIndex((value) => {
        return value.loadItemId === loadItemId;
      });
      const newData = currentData.map((value) => Object.assign({}, value));
      if (matchIndex >= 0) {
        newData.splice(matchIndex, 1, record);
      }
      return newData;
    });
  }

  // Get List invoices when filter and page change
  useEffect(() => {
    setLoading(true);
    const getInvoice = async () => {
      const data = await fetchDataByFilter({
        page: currentPage,
        pageSize: pageSize,
        ...filter,
        searchTerm: filter.searchTerm ? filter.searchTerm : undefined,
        from: filter.from ? filter.from : undefined,
        to: filter.to ? filter.to : undefined,
        depot: filter.depot.length ? filter.depot : undefined,
        type: filter.type ? filter.type : undefined
      });

      totalCount.current = data.totalInvoices;
      setLoading(false);
      setData(data.listInvoices);
      setCurrentPage(data.currentPage);
    };
    getInvoice();
  }, [currentPage, pageSize, filter]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setCurrentPageSize(pageSize);
  };

  const handleFilterChange = (filter) => {
    setFilter(filter);
    setCurrentPage(1);
  };

  const downloadPreview =  async () =>{
    await downLoadPreviewBatch({
      ...filter,
      searchTerm: filter.searchTerm ? filter.searchTerm : undefined,
      from: filter.from ? filter.from : undefined,
      to: filter.to ? filter.to : undefined,
      depot: filter.depot.length ? filter.depot : undefined,
      type: filter.type ? filter.type : undefined
    });
  };

  function changeState(loadItemId, nextState, currentState) {
    setChangingItem(loadItemId);
    changeItemState(loadItemId, nextState, (isSuccessful) => {
      if (isSuccessful) {
        notification.close(loadItemId);
        const openNotification = () => {
          const key = loadItemId;
          notification.open({
            message: loadItemId,
            description: (
              <StateChangeNotification
                currentState={currentState}
                nextState={nextState}
                onRevertClick={() => {
                  setChangingItem(loadItemId);
                  changeItemState(loadItemId, currentState, () => {
                    setChangingItem(null);
                  });
                  notification.close(key);
                }}
              />
            ),
            key,
          });
        };
        setTimeout(() => {
          openNotification();
        }, 200);
      }
      setChangingItem(null);
    });
  }

  const handleCheckboxChange = (isChecked, loadItemId, currentState) => {
    if (isChecked) {
      changeState(loadItemId, InvoiceStatus.ToBeInvoiced, currentState);
    } else {
      changeState(loadItemId, InvoiceStatus.Open, currentState);
    }
  };
  const handleCheckAll = (isChecked) => {
    setCheckingAll(true);
    if (isChecked) {
      const listItemToChange = data.filter(
        (item) =>
          item.status === InvoiceStatus.Flagged ||
          item.status === InvoiceStatus.Open
      );
      listItemToChange.forEach((item, index) => {
        if (index === listItemToChange.length - 1) {
          var callback = (isSuccessful) => {
            setCheckingAll(false);
          };
        }
        changeItemState(item.loadItemId, InvoiceStatus.ToBeInvoiced, callback);
      });
    } else {
      const listItemToChange = data.filter(
        (item) => item.status === InvoiceStatus.ToBeInvoiced
      );
      listItemToChange.forEach((item, index) => {
        if (index === listItemToChange.length - 1) {
          var callback = (isSuccessful) => {
            setCheckingAll(false);
          };
        }
        changeItemState(item.loadItemId, InvoiceStatus.Open, callback);
      });
    }
  };

  async function changeItemState(loadItemId, state, callback) {
    const changeFailtNoti = (error) => {
      notification.open({
        message: "Change Failed !",
        description: `${error}`,
        icon: <CloseCircleOutlined style={{ color: "#f5222d" }} />,
      });
    };

    try {
      await setItemState(loadItemId, state);
      if (callback) {
        callback(true);
      }
    } catch (error) {
      changeFailtNoti(error);
      if (callback) {
        callback(false);
      }
    }
  }

  return (
    <>
      <div className="page invoicing-page">
        <div className="inner">
          <div className="title">
            <div className="pageTitle">
              <FileTextOutlined />
              <h2>{translate("Invoices_Title")}</h2>
            </div>
            <CreateBatchModal
              untilDate={filter.to ?? dayjs()}
              records={data}
              disabled={isLoading || isCheckingAll}
            />
          </div>
          <Spin spinning={isLoading || isCheckingAll}>
            <DepotProvider>
              <InvoicesFilter
                filter={filter}
                onPreviewDownload={downloadPreview}
                isPreviewDisabled={totalCount.current == 0 || totalCount.current >= 10000}
                onFilterChange={handleFilterChange}
              />
            </DepotProvider>
            <div className="table-content">
              <ProductProvider>
                <stateInvoicesContext.Provider
                  value={{ changeState, handleInvoiceInfoChange }}
                >
                  <InvoicesTable
                    invoiceList={data}
                    onCheckAllChange={handleCheckAll}
                    onCheckItemChange={handleCheckboxChange}
                    isLoading={isLoading}
                    changingItem={changingItem}
                  />
                  <EditInvoiceModal />
                </stateInvoicesContext.Provider>
              </ProductProvider>
              <Pagination
                current={currentPage}
                total={totalCount.current}
                showTotal={(total, range) => {
                  return stringArgReplace(
                    translate("Invoices_Pagination_Range"),
                    `${range[0]}-${range[1]}`,
                    total
                  );
                }}
                defaultPageSize={defaultPageSize}
                defaultCurrent={defaultCurrentPage}
                onChange={handlePageChange}
              />
            </div>
          </Spin>
        </div>
      </div>
    </>
  );
}

export { stateInvoicesContext };
