import dayjs from "dayjs";
import { ArrowIcon } from "../../components";
import { useEffect, useRef, useState } from "react";
import { Divider } from "antd";
import useLocales from "../../hooks/useLocales";

export default function InvoiceStatus({ record }) {
  const ref = useRef();
  const {translate} = useLocales() 
  const [itemWidth, setItemWidth] = useState(0);
  useEffect(() => {
    if (ref.current) {
      setItemWidth(ref.current.offsetWidth);
    }
  }, [ref.current]);
  var statusList = [];
  function getStatus() {
    if (record.ordered) {
      statusList.push({
        status: translate("EditInvoice_Status_Ordered"),
        date:
          dayjs(record.ordered).format("D.M.YYYY") +
          " " +
          dayjs(record.ordered).format("hh:mm"),
        place: record.orderOrigin + " (#" + record.orderIdentifier + ")"
      });
    }
    if (record.picked) {
      statusList.push({
        status: translate("EditInvoice_Status_Picked"),
        date:
          dayjs(record.picked).format("D.M.YYYY") +
          " " +
          dayjs(record.picked).format("hh:mm"),
        place: record.customer.addresses[0].postNumber + " " + record.customer.addresses[0].postOffice
      });
    }
    if (record.arrived) {
      statusList.push({
        status: translate("EditInvoice_Status_Delivered"),
        date:
          dayjs(record.arrived).format("D.M.YYYY") +
          " " +
          dayjs(record.arrived).format("hh:mm"),
        place: record.deliveryDepotName
      });
    }
  }

  getStatus();
  return (
    <div className="invoiceStatusContainer">
      <div className="processContainer">
        {statusList.map((item, index) => {
          const refProp = index === 0 ? { ref: ref } : {};
          return (
            <div key={index}
              className={index < statusList.length - 1 ? "statusItem" : ""}
              {...refProp}
            >
              <strong>{item.status}</strong>
              {index < statusList.length - 1 && (
                <div className="statusItemArrow">
                  <ArrowIcon />
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="processContainer">
        {statusList.map((item, index) => {
          const styleProp = index === 0 ? { style: { width: itemWidth } } : {};
          return (
            <div key={index}
              className={index <= statusList.length - 1 && index > 0 ? (index === (statusList.length - 1) ? "lastItem" : "middleItem") : ""}
              {...styleProp}
            >
              <div>{item.place}</div>
              <div>{item.date}</div>
            </div>
          );
        })}
      </div>
      <Divider style={{ marginTop: "10px" }} />
    </div>
  );
}
