export default function ArrowIcon() {
  return (
    <svg
      viewBox="0 0 213 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M212.354 4.35355C212.549 4.15829 212.549 3.84171 212.354 3.64645L209.172 0.464466C208.976 0.269204 208.66 0.269204 208.464 0.464466C208.269 0.659728 208.269 0.976311 208.464 1.17157L211.293 4L208.464 6.82843C208.269 7.02369 208.269 7.34027 208.464 7.53553C208.66 7.7308 208.976 7.7308 209.172 7.53553L212.354 4.35355ZM0 4.5H212V3.5H0V4.5Z"
        fill="black"
      />
    </svg>
  );
}
