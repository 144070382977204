import { createContext, useState ,useEffect} from "react";
import { getDepotList } from ".";

const DepotContext = createContext()

export default function DepotProvider({children}){
    const [depotList,setDepotList] = useState();
    const [isLoadingDepot,setLoadingDepotList] = useState(false);

    useEffect(() => {
        getDepotArrivalYard();
      }, []);
    
      async function getDepotArrivalYard() {
        setLoadingDepotList(true)
        try {
          const depotList = await getDepotList();
          setDepotList(depotList);
        } catch (error) {
            console.log(error)
        }
        setLoadingDepotList(false)

      }

    function getDepotNameById(id){
      const foundDepot = depotList && depotList.find(depot=>depot.id == id)
      return foundDepot && foundDepot.name
    }

    function getDepotIdByName(name){
      const foundDepot = depotList && depotList.find(depot=>depot.name == name)
      return foundDepot && foundDepot.id
    }


    return (<DepotContext.Provider value={{depotList:depotList,isLoadingDepot,getDepotNameById,getDepotIdByName}}>
        {children}
    </DepotContext.Provider>)
}

export {DepotContext}