
import { Result } from "antd";


export default function UserRoleGuard({children}) {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      {user?.role.includes("CadaverInvoicing::rw") ? 
      <>{children}</>
       : (
        <Result
          status="warning"
          title="User doesn't have access privileges to this fearture"
        />
      )}
    </>
  );
}
