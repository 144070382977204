import React from 'react';
import App from './App';
import Cookies from 'js-cookie';
import {
  AuthProvider, 
  AuthService, 
  useAuth, 
} from 'react-oauth2-pkce';

const authService = new AuthService({ 
  clientId: process.env.REACT_APP_CLIENT_ID, 
  redirectUri: process.env.REACT_APP_REDIRECT_URI || window.location.origin, 
  authorizeEndpoint: process.env.REACT_APP_AUTHORIZE_ENDPOINT,
  tokenEndpoint : process.env.REACT_APP_TOKEN_ENDPOINT,
  logoutEndpoint: process.env.REACT_APP_LOGOUT_ENDPOINT,
  autoRefresh: false,
  scopes: ['openid', 'offline_access', 'role','neviso.helos.api'], 
});

function SecuredApp() {
    const { authService } = useAuth(); 

    const login = async () => authService.authorize(); 
    const logout = async () => {
        await authService.logout(true); 
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('filter')
    }
    if (authService.isPending()) { 
        return <div>
            Loading...
            <button onClick={() => { logout(); login(); }}>Reset</button>
        </div>
    }

    if (!authService.isAuthenticated()) { 
        return (
            <div>
                <p>Not Logged in yet</p>
                <button onClick={login}>Login</button>
            </div>
        )
    }

    const token = authService.getAuthTokens(); 
    try{
        const user = authService.getUser();
        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('user', JSON.stringify(user));
        return ( 
            <div>
                <p>Logged in as "{user.sub}"</p>
                <button onClick={logout}>Logout</button>
                <App />
            </div>
        );
    }catch(err){
        return (
            <div>
                <p>Not Logged in yet</p>
                <button onClick={login}>Login</button>
            </div>
        )
    }
    
}

function WrappedSecuredApp() {
    return (
        <AuthProvider authService={authService} > 
            <SecuredApp />
        </AuthProvider>
    );
}

export default WrappedSecuredApp;
export {authService};