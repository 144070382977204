
import { ConfigProvider } from 'antd';
import i18n from '../translations/i18n';
import { allLangs ,defaultLang} from '../config';



export default function LocalesProvider({children}){
    var matchLang = allLangs.find((lang)=>lang.value===i18n.language)
    var locale = matchLang?.systemValue || defaultLang.systemValue

    return (
      <ConfigProvider locale={locale}>
        {children}
      </ConfigProvider>
    );
}
