import {
  FlagFilled,
  LoadingOutlined,
  InfoCircleTwoTone
} from "@ant-design/icons";
import {
  Table,
  Checkbox,
  Spin,
  Tooltip
} from "antd";
import dayjs from "dayjs";

import { InvoiceStatus } from "../../const";

//hooks
import useLocales from "../../hooks/useLocales";
//components
import MoreActionDropDown from "./MoreActionDropDown";

export default function InvoicesTable({ invoiceList, onCheckAllChange, onCheckItemChange, changingItem, isLoading }) {
  const { translate } = useLocales()
  const isCheckAll = () => {
    if (invoiceList && invoiceList.length > 0) {
      for (const item of invoiceList) {
        if (
          item.status !== InvoiceStatus.ToBeInvoiced &&
          item.status !== InvoiceStatus.Invoiced
        )
          return false;
      }
    } else {
      return false;
    }
    return true;
  };

  const isAllInvoiced = () => {
    for (const item of invoiceList) {
      if (item.status !== InvoiceStatus.Invoiced) return false;
    }
    return true;
  };

  const columns = [
    {
      title: (
        <>
          {isAllInvoiced() ? null : (
            <Checkbox
              defaultChecked={isCheckAll()}
              checked={isCheckAll()}
              onChange={(e) => {
                onCheckAllChange(e.target.checked);
              }}
            />
          )}
        </>
      ),
      render: (text, record) => {
        return (
          <>
            {record.status === InvoiceStatus.Invoiced ? null : changingItem ===
              record.loadItemId ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: "15px" }} />}
              />
            ) : (
              <Checkbox
                defaultChecked={record.status === InvoiceStatus.ToBeInvoiced}
                checked={record.status === InvoiceStatus.ToBeInvoiced}
                onChange={(e) => {
                  onCheckItemChange(
                    e.target.checked,
                    record.loadItemId,
                    record.status
                  );
                }}
              />
            )}
          </>
        );
      },
    },
    {
      title: translate("Invoices_Table_Load"),
      dataIndex: "loadId",
    },
    {
      title: translate("Invoices_Table_Customer"),
      dataIndex: "customer",
      render: (i, row) => (row.customer && row.customer.helosId === row.invoiceableCustomer.helosId ? row.customer.name :
        <>
          <p>{row.customer.name}</p>
          <p style={{ color: "#8c8c8c", margin: "unset" }}>{row.invoiceableCustomer.name}</p>
        </>
      ),
    },
    {
      title: translate("Invoices_Table_ExternalID"),
      dataIndex: ["invoiceableCustomer", "externalId"],
      width: 120,
    },
    {
      title: translate("Invoices_Table_Delivery"),
      dataIndex: "arrived",
      render: (i) => (i ? dayjs(i).format("D.M.YYYY") : ""),
    },
    {
      title: translate("Common_Depot"),
      dataIndex: "deliveryDepotShortName",
      render: (i, record) => {
        return <>
          <Tooltip title={record.deliveryDepotName}>
            <span>{record.deliveryDepotShortName}</span>
          </Tooltip>
        </>
      }
    },
    {
      title: translate("Invoices_Table_Product"),
      dataIndex: "productName",
    },
    {
      title: translate("Invoices_Table_Weight"),
      dataIndex: "weight",
      render: (_, record) => {
        return <>
          {record.quantity > 1 ?
            <>
              <span>{record.quantity}</span>
              <strong style={{ color: "#1890ff" }}> x </strong>
              <span><i>~{record.weight}</i></span>
            </> :
            <>
              {record.weighed ? <span>{record.weighed}</span> : <span><i>~{record.weight}</i></span>}
            </>
          }
        </>
      }
    },
    {
      title: translate("Invoices_Table_Price"),
      dataIndex: "price",
    },
    {
      title: "",
      dataIndex: "invoicingReference",
      render: (i, record) => {
        return record.invoicingReference ?
          <>

            <Tooltip title={record.invoicingReference}>
              <InfoCircleTwoTone />
            </Tooltip>
          </> : <></>
      }
    },
    {
      title: translate("Invoices_Table_InvoicingBatch"),
      dataIndex: "batchId",
      width: 200,
      render: (text, record) => {
        switch (record.status) {
          case InvoiceStatus.Invoiced:
            return (
              <>
                <span style={{ color: "#1976D2" }}>
                  <strong>{record.batchId} </strong>
                </span>
                <span>{record.invoiced ? dayjs(record.invoiced).format("D.M.YYYY") : "No data"}</span>
              </>
            );
          case InvoiceStatus.Flagged:
            return (
              <FlagFilled
                style={{
                  color: "#fa8c16",
                  fontSize: "20px",
                }}
              />
            );
          case InvoiceStatus.ToBeInvoiced:
            return <></>;
          case InvoiceStatus.Open:
          default:
            return <></>;
        }
      },
    },
    {
      title: "",
      dataIndex: "actions",
      render: (text, record) => {
        return (
          <>
            <MoreActionDropDown record={record} />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        rowClassName={(record) => {
          switch (record.status) {
            case InvoiceStatus.Invoiced:
              return "row_invoiced";
            case InvoiceStatus.Flagged:
              return "row_flagged";
            case InvoiceStatus.ToBeInvoiced:
              return "row_tobeInvoice";
            case InvoiceStatus.Open:
            default:
              return "";
          }
        }}
        pagination={false}
        columns={columns}
        dataSource={isLoading ? null : invoiceList}
      />
    </>
  );
}
