import { Button } from "antd";
import { UndoOutlined, ArrowRightOutlined } from "@ant-design/icons";

import { InvoiceStatus } from "../../const";

import useLocales from "../../hooks/useLocales";

const colorMap = {
  [InvoiceStatus.Flagged]: "#fa8c16",
  [InvoiceStatus.ToBeInvoiced]: "#1890ff",
  [InvoiceStatus.NotToBeInvoiced]: "#f5222d",
  [InvoiceStatus.Open]: "#ffff",
};

export default function StateChangeNotification({
  currentState,
  nextState,
  onRevertClick,
}) {
  const {translate} = useLocales()

  return (
    <>
      <div className="toastContainer">
        <div className="stateChangeFlow">
          <div
            className="stateItem"
            style={{
              background: colorMap[currentState],
              color:
                currentState === InvoiceStatus.Open ? "#000000" : "#ffffff",
            }}
          >
            {currentState}
          </div>
          <ArrowRightOutlined />
          <div
            className="stateItem"
            style={{
              background: colorMap[nextState],
              color: nextState === InvoiceStatus.Open ? "#000000" : "#ffffff",
            }}
          >
            {nextState === InvoiceStatus.NotToBeInvoiced
              ? "Removed"
              : nextState}
          </div>
        </div>
        {nextState !== InvoiceStatus.NotToBeInvoiced && (
          <Button
            size="small"
            icon={<UndoOutlined />}
            style={{ color: "#bfbfbf" }}
            onClick={onRevertClick}
          >
            {translate("Common_Revert")}
          </Button>
        )}
      </div>
    </>
  );
}
