import customerAPI from "../../api/customerAPI";
import axios from "../../api/axiosClient";

let source = axios.CancelToken.source()
export async function getInvoicebleCustomerList(searchTerm,params){
    try {
        const {data} = await customerAPI.getCustomerList(searchTerm,{...params})
        return {
            listCustomer : data.items,
            pageIndex:data.pageIndex,
            totalCount:data.totalCount
        };
    } catch (error) {
        throw new Error(error)
    }
}

export function cancelGetInvoicebleCustomer(){
    source.cancel();
}