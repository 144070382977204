import { useContext, useState } from "react";
import { Icon } from "@iconify/react";
import dayjs from "dayjs";
import { Button, Col, Input, Row, Select } from "antd";
import styled from "styled-components";

import { FileExcelTwoTone } from "@ant-design/icons";

import { InvoiceStatus } from "../../const";
import { DatePicker } from "../../components";
import { DepotContext } from "../../services/DepotService/DepotProvider";
import useLocales from "../../hooks/useLocales";

const { Search } = Input;
const { Option } = Select;

const Container = styled.div`
  width: 100%;
  padding: 30px 0px 10px 0px;
  border-bottom: 1px solid #ccc;
`;

const StyledInputContainer = styled.div`
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  position: relative;
  .label {
    left: 15px;
    top: -12px;
    font-size: 13px;
    background: #fff;
    padding: 0 5px;
    position: absolute;
    z-index: 2;
  }
  input {
    border-radius: 5px 0px 0px 5px;
    font-size: 16px;
  }
  .ant-input-group-addon {
    .ant-btn {
      border-radius: 0px 5px 5px 0px;
    }
  }
  .ant-picker {
    border-radius: 5px;
    width: 100%;
  }

  .ant-select {
    width: 100%;
    .ant-select-selector {
      border-radius: 5px;
    }
  }

  .ant-btn{
    border-radius: 5px;
  }
`;

export default function InvoicesFilter({ filter, onFilterChange, onPreviewDownload, isPreviewDisabled }) {
  const { depotList, isLoadingDepot, getDepotIdByName, getDepotNameById } =
    useContext(DepotContext);
  const { translate } = useLocales();
  const [filterParams, setFilter] = useState(filter);  

  const handleClearFilter = (value) => {
    const updatedFilter = {
      ...filterParams,
      searchTerm: "",
      from: null,
      to: null,
      depot: [],
      type: "",
    };
    setFilter(updatedFilter);
    onFilterChange(updatedFilter);
  };

  const handleSearchTermChange = (value) => {
    if (value !== filterParams.searchTerm) {
      const updatedFilter = { ...filterParams, searchTerm: value };
      setFilter(updatedFilter);
    }
  };

  const handleSearchClick = () => {
    onFilterChange(filterParams);
  };

  const handleSelectStatusChange = (value) => {
    const updatedFilter = { ...filterParams, type: value };
    setFilter(updatedFilter);
    onFilterChange(updatedFilter);
  };

  const handleFromDateChange = (dates) => {
    var fromDate = null;
    var toDate = null;
    if (dates && dates[0] !== null) {
      fromDate = dayjs(dates[0].toISOString());
    }
    if (dates && dates[1] != null) {
      toDate = dayjs(dates[1].toISOString());
    }
    const updatedFilter = { ...filterParams, from: fromDate, to: toDate };
    setFilter(updatedFilter);
    onFilterChange(updatedFilter);
  };

  const handleDepotsChange = (value) => {
    const listDepotId = value && value.map((name) => getDepotIdByName(name));
    const updatedFilter = { ...filterParams, depot: listDepotId };
    setFilter(updatedFilter);
    onFilterChange(updatedFilter);
  };

  const ranges = () => {
    const range = {};
    range[translate('Common_Today')] = [dayjs(), dayjs()];
    range[translate('Common_ThisMonth')] = [dayjs().startOf('month'), dayjs()];
    range[translate('Common_Last2Weeks')] = [dayjs().subtract(2, 'week'), dayjs()];
    return range;
  };

  const downloadPreview = () => {
    onPreviewDownload();
  };


  return (
    <>
      <Container>
        <Row gutter={[16, 16]} style={{ marginBottom: 8 }}>
          <Col span={6}>
            <StyledInputContainer>
              <Search
                placeholder={translate("Common_Search")}
                size="large"
                value={filterParams.searchTerm}
                onChange={(e) => {
                  handleSearchTermChange(e.target.value);
                }}
                onSearch={(value) => {
                  handleSearchClick();
                }}
              />
            </StyledInputContainer>
          </Col>

          <Col span={6}>
            <DatePicker.RangePicker
              allowClear
              format={"D.M.YYYY"}
              size="large"
              allowEmpty={[true, true]}
              ranges={ranges()}
              placeholder={[translate("Common_From"), translate("Common_Until")]}
              value={[filterParams.from ? dayjs(filterParams.from) : null, filterParams.to ? dayjs(filterParams.to) : null]}
              onChange={handleFromDateChange}
            />
          </Col>

          <Col span={6}>
            <StyledInputContainer>
              <span className="label">{translate("Common_Depot")}</span>
              {isLoadingDepot ? null : (
                <Select
                  mode="multiple"
                  allowClear
                  size="large"
                  value={
                    filterParams.depot &&
                    filterParams.depot.map((id) => getDepotNameById(id))
                  }
                  onChange={handleDepotsChange}
                >
                  {depotList &&
                    depotList
                      .filter((depot) => depot.type === "ArrivalYard")
                      .map((depot) => (
                        <Option key={depot.id} value={depot.name}>
                          {depot.name}
                        </Option>
                      ))}
                </Select>
              )}
            </StyledInputContainer>
          </Col>

          <Col span={4}>
            <StyledInputContainer>
              <span className="label">{translate("Common_Status")}</span>
              <Select
                value={filterParams.type}
                size="large"
                onChange={handleSelectStatusChange}
              >
                <Option value="">{translate("Common_All")}</Option>
                <Option value={InvoiceStatus.Open}>
                  {translate("Common_Open")}
                </Option>
                <Option value={InvoiceStatus.Flagged}>
                  {translate("Common_Flagged")}
                </Option>
                <Option value={InvoiceStatus.ToBeInvoiced}>
                  {translate("Common_ToBeInvoiced")}
                </Option>
                <Option value={InvoiceStatus.Invoiced}>
                  {translate("Common_Invoiced")}
                </Option>
              </Select>
            </StyledInputContainer>
          </Col>

          <Col >
            <StyledInputContainer>
              <Button
                icon={<Icon icon="carbon:filter-remove" />}
                onClick={handleClearFilter}
                size="large"
              />
            </StyledInputContainer>
          </Col>
        </Row>
        <Row justify="space-between" align="bottom" gutter={[16, 16]}>
          <Col span={12}></Col>
          <Col>
            <StyledInputContainer>
              <Button type="dashed" disabled={isPreviewDisabled}
                onClick={() => downloadPreview()} icon={<FileExcelTwoTone />}>{translate("Invoices_Filter_Preview")}</Button>
            </StyledInputContainer>
          </Col>
        </Row>
      </Container>
    </>
  );
}
