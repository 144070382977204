//format : page_component_description

const nor = {
  //Locacle for common
  Common_Save:"Lagre",
  Common_Confirm:"Bekrefte",
  Common_Download:"nedlasting",
  Common_Cancel:"Avbryt",
  Common_Close:"Lukk",
  Common_Revert:"Gå tilbake",

  Common_Search:"Søk",
  Common_From:"Fra",
  Common_Until:"Til",
  Common_Today:"I dag",
  Common_ThisMonth:"Denne måneden",
  Common_Last2Weeks:"Siste 2 uker",
  Common_Depot:"Depot",

  Common_Status:"Status",
  Common_All:"Alle",
  Common_Flagged:"Flagget",
  Common_ToBeInvoiced:"Å faktureres",
  Common_Invoiced:"Fakturert",
  Common_Open:"Åpen",
  Common_Invoice:"Faktura",

  //Locale invoices page
  Invoices_Title:"Kadaverfakturering",
  Invoices_Table_Load:"Laste",
  Invoices_Table_Customer:"Kunde",
  Invoices_Table_ExternalID:"Ext. ID",
  Invoices_Table_Delivery:"Leveranse",
  Invoices_Table_Product:"Produkt",
  Invoices_Table_Weight:"Vekt",
  Invoices_Table_Price:"Pris",
  Invoices_Table_InvoicingBatch:"Fakturerende batch",  
  Invoices_Filter_Preview:"Eksporter forhåndsvisning",
  
  Invoices_Pagination_Range:"%1 av %2 varer",

  Invoices_Batch_BtnTitle:"Utvalgte fakturaer",
  Invoices_Batch_Description:"Fakturaer valgt for batch",
  Invoices_Batch_InvoiceBatch:"Faktura batch",
  Invoices_Batch_SelectDate:"Velg dato",
  Invoices_Batch_BatchIndex:"Parti",

  Invoices_Dropdown_Flag:"Flagg",
  Invoices_Dropdown_Remove:"Fjerne",
  Invoices_Dropdown_Edit:"Redigere",
  Invoices_Dropdown_UnFlag:"Ukjente",
  Invoices_Dropdown_DeleteInvoice:"Slett faktura",
  Invoices_Dropdown_DeleteInvoice_Confirm:"Bekreft at den valgte fakturaen blir fjernet permanent ...",
  Invoices_Dropdown_Created:"Opprettet",
  Invoices_Dropdown_CreatedBy:"Laget av",

  //Locale for edit page
  EditInvoice_Header_Detail:"Detalj",

  EditInvoice_Status_Ordered:"Bestilt",
  EditInvoice_Status_Picked:"Valgt",
  EditInvoice_Status_Delivered:"Levert",

  EditInvoice_Customer_Order:"Bestill kunde",
  EditInvoice_Customer_Invoice:"Fakturekunde",
  EditInvoice_Customer_Select:"Velg kunde",

  EditInvoice_Product_Name:"Produktnavn",
  EditInvoice_Product_Weight:"Vekt",
  EditInvoice_Product_Quantity:"Mengde",

  EditInvoice_Price_Quantity:"Pris (NOK)",
  EditInvoice_Price_Tax:"Avgift",
  EditInvoice_Price_Discount:"Rabatt",
  EditInvoice_Price_Total:"Totalt (NOK)",

  
  EditInvoice_Reference:"Faktureringsreferanse",
  EditInvoice_NoValidPriceFound:"Ingen gyldig pris funnet for produktet under bestillingstidspunktet"
}

export default nor;