import depotAPI from "../../api/depotAPI";

export async function getDepotList(){
    try {
        const {data} = await depotAPI.getDepotList({type:"ArrivalYard"})
        return data
    } catch (error) {
        throw new Error(error)
    }
}
