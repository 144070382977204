import enUS from 'antd/es/locale/en_US';
import nbNO from 'antd/es/locale/nb_NO';
import fiFI from 'antd/es/locale/fi_FI';

export const allLangs = [
    {
      label: 'English',
      value: 'en',
      systemValue: enUS,
    },
    {
      label: 'Fin',
      value: 'fi',
      systemValue: fiFI,
    },
    {
      label: 'Nor',
      value: 'no',
      systemValue: nbNO,
    },
  ];
  
  export const defaultLang = allLangs[0]; // English