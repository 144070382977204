import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/lib/date-picker/style/index';
import dayjs from 'dayjs';
import fin from 'dayjs/locale/fi'
import nor from 'dayjs/locale/nb'
import updateLocale from 'dayjs/plugin/updateLocale'

//Load locale
dayjs.locale('fi')
dayjs.locale('nb')
dayjs.extend(updateLocale)

//Format english locale start from mon
dayjs.updateLocale('en', {
    weekStart: 1,
  })

const DatePicker = generatePicker(dayjsGenerateConfig);

export default DatePicker;