import InputNumber from "../../components/InputNumber/InputNumber";
import CustomSelect from "../../components/Select/Select";
import { Divider, Select } from "antd";
import { useRef } from "react";
import useLocales from "../../hooks/useLocales";

const { Option } = Select

export default function EditProduct({ listProduct, isLoadingProduct = false, product, onProductChange, isReadOnly }) {
  const productRef = useRef({
    ...product
  })
  const { translate } = useLocales()
  const listOption = listProduct && listProduct.map((product, index) => {
    return (
      <Option key={index} value={product.id}>{product.name}</Option>
    )
  })
  console.log("is-read-only:" + isReadOnly)

  const currentProduct = listProduct.find((item) => item.id === product.productId)
  const productName = currentProduct && currentProduct.name
  const allowMany = currentProduct && currentProduct.allowMany;

  function handleProductChange(value) {
    productRef.current = { ...productRef.current, productId: value }
    onProductChange && onProductChange(productRef.current)
  }
  function handleWeightChange(value) {
    productRef.current = { ...productRef.current, weight: value }
    onProductChange && onProductChange(productRef.current)
  }
  function handleQuantityChange(value) {
    productRef.current = { ...productRef.current, quantity: value }
    onProductChange && onProductChange(productRef.current)
  }

  return (
    <>
      <strong>{translate("Invoices_Table_Product")}</strong>
      <div className="productEditContainer">
        <CustomSelect
          disabled={isReadOnly}
          style={{ width: "300px" }}
          isLoading={isLoadingProduct}
          label={translate("EditInvoice_Product_Name")}
          defaultValue={productName}
          onChange={handleProductChange}
        >
          {listOption}
        </CustomSelect>
        <InputNumber
          className={"inputColum"}
          label={translate("EditInvoice_Product_Weight")}
          min={0}
          disabled={allowMany || isReadOnly}
          controls={false}
          defaultValue={product.weight}
          onChange={handleWeightChange}
        />
        <InputNumber
          className={"inputColum"}
          label={translate("EditInvoice_Product_Quantity")}
          min={1}
          disabled={!allowMany || isReadOnly}
          controls={false}
          defaultValue={product.quantity}
          onChange={handleQuantityChange}
        />
      </div>
      <Divider />
    </>
  );
}
