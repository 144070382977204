const fin = {
  //Locacle for common
  Common_Save:"Tallenna",
  Common_Confirm:"Vahvista",
  Common_Download:"Lataa",
  Common_Cancel:"Peruuta",
  Common_Close:"Sulje",
  Common_Revert:"Peru",

  Common_Search:"Etsi",
  Common_From:"Mistä",
  Common_Until:"Mihin",
  Common_Today:"Tänään",
  Common_ThisMonth:"Tässä kuussa",
  Common_Last2Weeks:"Viimeiset 2 viikkoa",
  Common_Depot:"Varikko",

  Common_Status:"Tila",
  Common_All:"Kaikki",
  Common_Flagged:"Liputettu",
  Common_ToBeInvoiced:"Laskutukseen",
  Common_Invoiced:"Laskutettu",
  Common_Open:"Avoin",
  Common_Invoice:"Lasku",

  //Locale invoices page
  Invoices_Title:"Raatolaskutus",
  Invoices_Table_Load:"Kuorma",
  Invoices_Table_Customer:"Asiakas",
  Invoices_Table_ExternalID:"Ulk. Tunnus",
  Invoices_Table_Delivery:"Toimitus",
  Invoices_Table_Product:"Tuote",
  Invoices_Table_Weight:"Paino",
  Invoices_Table_Price:"Hinta",
  Invoices_Table_InvoicingBatch:"Laskutuserä",
  Invoices_Filter_Preview:"Lataa esikatselu",
  
  Invoices_Pagination_Range:"%1 :stä %2 rivistä",

  Invoices_Batch_BtnTitle:"Valitut laskut",
  Invoices_Batch_Description:"Laskuja valittuna erään",
  Invoices_Batch_InvoiceBatch:"Laskutuserä",
  Invoices_Batch_SelectDate:"Valitse päivämäärä",
  Invoices_Batch_BatchIndex:"Erä",

  Invoices_Dropdown_Flag:"Liputa",
  Invoices_Dropdown_Remove:"Poista",
  Invoices_Dropdown_Edit:"Muokkaa...",
  Invoices_Dropdown_UnFlag:"Poista liputus",
  Invoices_Dropdown_DeleteInvoice:"Poista...",
  Invoices_Dropdown_DeleteInvoice_Confirm:"Vahvista että tämä rivi poistetaan kokonaan...",
  Invoices_Dropdown_Created:"Luonut",
  Invoices_Dropdown_CreatedBy:"Luotu",

  //Locale for edit page
  EditInvoice_Header_Detail:"",

  EditInvoice_Status_Ordered:"Tilattu",
  EditInvoice_Status_Picked:"Noudettu",
  EditInvoice_Status_Delivered:"Toimitettu",

  EditInvoice_Customer_Order:"Tilaaja",
  EditInvoice_Customer_Invoice:"Laskutettava",
  EditInvoice_Customer_Select:"Valitse...",

  EditInvoice_Product_Name:"Tuote",
  EditInvoice_Product_Weight:"Paino",
  EditInvoice_Product_Quantity:"Määrä",

  EditInvoice_Price_Quantity:"Hinta €",
  EditInvoice_Price_Tax:"Vero",
  EditInvoice_Price_Discount:"Alennus",
  EditInvoice_Price_Total:"Yhteensä €",

  
  EditInvoice_Reference:"Laskutus viite",
  EditInvoice_NoValidPriceFound:"Tuotteelle ei löytynyt hintaa tilauksen tekohetkeltä"
}

export default fin;