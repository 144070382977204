import { SearchOutlined } from "@ant-design/icons";
import { Select, Empty, Spin, Pagination } from "antd";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import "./StyleSelectCustomer.scss";
import { cancelGetInvoicebleCustomer, getInvoicebleCustomerList } from "../../../services/CustomerService";
import useLocales from "../../../hooks/useLocales";
const {Option} = Select

export default function SelectWithSearch({onChange,...props}) {
  const [listCustomer,setListCustomer] = useState()
  const [isFetchingCustomer,setFetchingCustomer] = useState(false);
  const [filter,setFilter] = useState({
      searchTerm:"",
      page:1,
      pageSize:10
  })
  const timeoutRef = useRef();
  const totalCountRef = useRef(0);
  useEffect(()=>{
    cancelGetInvoicebleCustomer()
    clearTimeout(timeoutRef.current)
    getListCustomer()
  },[filter])

  const {translate} = useLocales()

  function getListCustomer(){
    if(filter.searchTerm.length > 2){
      timeoutRef.current = setTimeout(async () => {
        setFetchingCustomer(true)
        try {
          const data = await getInvoicebleCustomerList(filter.searchTerm,{page:filter.page,pageSize:filter.pageSize});
          setListCustomer(data.listCustomer)
          totalCountRef.current = data.totalCount
        } catch (error) {
        }
        setFetchingCustomer(false)
      }, 500);
    }
  }

  function handleSearchTermChange(value){
    if(value===""){
      setListCustomer([])
      totalCountRef.current=0
    }
    const newFilter = {...filter,searchTerm:value,page:1}
    setFilter(newFilter)
  }

  function handlePageChange(page){
    const newFilter = {...filter,page:page}
    setFilter(newFilter)
  }

  function handleSelectChange(value){
    const selectedCustomer = listCustomer.find(customer=>customer.helosId === value)
    onChange && onChange(selectedCustomer)
  }

  return (
    <div className={clsx("inputContainer")}>
      <Select
        showSearch
        autoFocus
        filterOption={false}
        placeholder={translate("EditInvoice_Customer_Select")}
        notFoundContent={
          isFetchingCustomer ? (
            <Spin size="small" />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
        onSearch={handleSearchTermChange}
        onChange={handleSelectChange}
        dropdownRender={(menu) => {
          return (
            <>
              {menu}
              {menu.length>0 && <Pagination
                style={{marginLeft:"5px"}}
                current={filter.page}
                size="small"
                total={totalCountRef.current}
                showLessItems={true}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total}`
                }
                pageSize={10}
                showSizeChanger={false}
                onChange={handlePageChange}
              />}
            </>
          );
        }}
        {...props}
      >
        {listCustomer && listCustomer.map(customer=>{
          const invoicingAddress = customer.addresses.find(address=>address.isInvoicingAddress)
          const displayAddress = invoicingAddress ? invoicingAddress.address : ""
          return <Option value={customer.helosId}>{customer.name}, {displayAddress}</Option>
        })}
      </Select>
    </div>
  );
}
