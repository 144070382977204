import { Modal, Divider, Typography, Spin, Empty, notification } from "antd";
import { useContext, useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged, tap, skip } from "rxjs/operators";
import { CancelToken } from "axios";

import { InvoiceStatus as InvoiceState } from "../../const";

// components
import InvoiceStatus from "../EditInvoicePage/InvoiceStatus";
import EditPrice from "../EditInvoicePage/EditPrice";
import EditProduct from "../EditInvoicePage/EditProduct";
import EditCustomer from "../EditInvoicePage/EditCustomer";
import ErrorNotification from "../InvoicesPage/ErrorNotification";

//service
import { ProductContext } from "../../services/ProductService/Provider";
import {
  setInvoicePrice,
  setInvoiceProduct,
  setInvoiceWeight,
  setInvoiceQuantity,
  setInvoicingCustomer,
  getInvoiceItem,
} from "../../services/InvoiceService";
import { stateInvoicesContext } from ".";
import ordersApi from "../../api/ordersApi";

//hooks
import useLocales from "../../hooks/useLocales";
import { t } from "i18next";

const { Paragraph, Title } = Typography;

export default function EditInvoiceModalProvider() {
  const { translate } = useLocales();

  const {
    products,
    isLoadingProduct,
    dataState: productState,
    getProductNameById,
  } = useContext(ProductContext);
  const { handleInvoiceInfoChange } = useContext(stateInvoicesContext);

  //init Data
  const [saving, setSaving] = useState(false);
  const [invoiceCustomer, setInvoiceCustomer] = useState(null);
  const [product, setProduct] = useState(null);
  const [price, setPrice] = useState(null);
  const [dataState, setDataState] = useState("init");
  const [record, setRecord] = useState(null);
  const orderCustomer = useRef(null);

  const [showEditModal, setShowEditModal] = useState(false);
  //   const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const modalStatus = searchParams.get("modal");
    if (modalStatus === "open") {
      setShowEditModal(true);
      const id = searchParams.get("editingItem");
      initInvoiceItem(id);
    }
    async function initInvoiceItem(id) {
      setDataState("loading");
      try {
        const invoiceItem = await getInvoiceItem(id);
        setRecord(invoiceItem);
        setInvoiceCustomer(invoiceItem.invoiceableCustomer);
        setProduct({
          productId: invoiceItem.productId,
          weight:
            invoiceItem.weighed && invoiceItem.weighed > 0
              ? invoiceItem.weighed
              : invoiceItem.weight,
          quantity: invoiceItem.quantity
        });
        setPrice({
          price: invoiceItem.price,
          discountPercentage: invoiceItem.discountPercentage,
          taxRate: invoiceItem.taxRate
        });
        orderCustomer.current = invoiceItem.customer;
        setProductChangeSubject(
          new BehaviorSubject({
            productId: invoiceItem.productId,
            weight: invoiceItem.weighed
              ? invoiceItem.weighed
              : invoiceItem.weight
          })
        );
        setDataState("loaded");
      } catch (error) {
        setRecord(null);
        setDataState("loaded");
      }
    }
  }, [searchParams]);

  const [productChangeSubject, setProductChangeSubject] = useState(null);
  const [priceCancel, setPriceCancel] = useState(CancelToken.source());
  const [isCalculatingPrice, setIsCalculatingPrice] = useState(false);
  const [isQuantityBased, setIsQuantityBased] = useState(true);

  useEffect(() => {
    productChangeSubject &&
      productChangeSubject
        .pipe(
          distinctUntilChanged((prev, curr) => {
            return (
              prev.weight === curr.weight && prev.productId === curr.productId
            );
          }),
          skip(1),
          tap((_) => setIsCalculatingPrice(true)),
          debounceTime(1000)
        )
        .subscribe(async (a) => {
          priceCancel.cancel();
          const token = CancelToken.source();
          setPriceCancel(token);
          try {
            const newPrice = await ordersApi.getPrice(
              record.orderItemId,
              a.productId,
              a.weight,
              token.token
            );
            handlePriceChange({
              price: newPrice,
              discountPercentage: price.discountPercentage,
              taxRate: price.taxRate,
            });
          }
          catch (err) {
            if (err.response.data.errors.productId) {
              notification.open({
                message: "error",
                description: (
                  <ErrorNotification
                    message={translate("EditInvoice_" + err.response.data.errors.productId[0].code)}
                  />
                )
              });
            }
          }

          setIsCalculatingPrice(false);
        });
  }, [productChangeSubject]);

  useEffect(() => {
    if (product) {
      setIsQuantityBased(isProductQuantityBased(product.productId));
    }
  }, [products]);


  function clearSearchParams() {
    searchParams.delete("modal")
    searchParams.delete("editingItem")
    setSearchParams(searchParams)
  }

  function handleProductChange(value) {
    setProduct({ ...value });
    setIsQuantityBased(isProductQuantityBased(value.productId));
    productChangeSubject.next({
      productId: value.productId,
      weight: value.weight,
    });
  }

  function handlePriceChange(value) {
    setPrice({ ...value });
  }

  function handleInvoiceCustomerChange(value) {
    setInvoiceCustomer(value);
  }

  function isReadOnly(){
    return record?.status === InvoiceState.Invoiced;
  }

  function checkInvalidInput() {
    if(isReadOnly()){
      return true;
    }
    if (dataState === "loaded") {
      if (
        (!product.weight && product.weight != 0) ||
        (!product.quantity && product.quantity != 0) ||
        (!price.price && price.price != 0) ||
        (!price.discountPercentage && price.discountPercentage != 0) ||
        (!price.taxRate && price.taxRate != 0)
      ) {
        return true;
      } else return false;
    }
    return true;
  }

  async function handleSaveClick() {
    try {
      setSaving(true);
      const newRecord = Object.assign({}, record);
      if (newRecord.invoiceableCustomer.helosId !== invoiceCustomer.helosId) {
        await setInvoicingCustomer(record.loadItemId, invoiceCustomer.helosId);
        newRecord.invoiceableCustomer = invoiceCustomer;
      }
      if (newRecord.productId !== product.productId) {
        await setInvoiceProduct(record.loadItemId, product.productId);
        newRecord.productName = getProductNameById(product.productId);
      }
      if (newRecord.weighed !== product.weight) {
        await setInvoiceWeight(record.loadItemId, product.weight);
        newRecord.weighed = product.weight;
      }
      if (newRecord.quantity !== product.quantity) {
        await setInvoiceQuantity(record.loadItemId, product.quantity);
        newRecord.quantity = product.quantity;
      }
      if (newRecord.price !== price.price || newRecord.discountPercentage !== price.discountPercentage || newRecord.taxRate !== price.taxRate) {
        await setInvoicePrice(record.loadItemId, {
          price: price.price,
          discountPercentage: price.discountPercentage,
          taxPercentage: price.taxRate,
        });
        newRecord.price = price.price;
        newRecord.discountPercentage = price.discountPercentage;
        newRecord.taxRate = price.taxRate;
      }

      handleInvoiceInfoChange(record.loadItemId, newRecord);
    } catch (error) { }
    setSaving(false);
    setShowEditModal(false);
    clearSearchParams()
  }

  function isProductQuantityBased(productId) {
    if (products) {
      return products.find(a => a.id == productId).allowMany;
    }
    return false;
  }

  return (
    <>
      <Modal
        visible={showEditModal}
        onOk={() => handleSaveClick()}
        okText={translate("Common_Save")}
        okButtonProps={{ disabled: checkInvalidInput() }}
        confirmLoading={saving}
        maskClosable={!saving}
        onCancel={() => {
          if (!saving) setShowEditModal(false);
          clearSearchParams()
        }}
        width={800}
        closable={false}
      >
        {dataState == "loaded" && productState == "loaded" ? (
          record ? (
            <div>
              <div className="titleContainer">
                <strong>{translate("EditInvoice_Header_Detail")} &nbsp;</strong>
                <strong style={{ color: "#1976D2" }}>{record.loadId}</strong>

                <Typography
                  style={{ marginLeft: "8px", fontSize: "10px" }}
                  type="secondary"
                >
                  {" "}
                  ({record.loadItemId})
                </Typography>
              </div>
              <InvoiceStatus record={record} />
              <EditCustomer
                isReadOnly={isReadOnly()}
                orderCustomer={orderCustomer.current}
                invoiceCustomer={invoiceCustomer}
                onInvoiceCustomerChange={handleInvoiceCustomerChange}
              />
              <EditProduct isReadOnly={isReadOnly()}
                isLoadingProduct={isLoadingProduct}
                listProduct={products}
                product={product}
                onProductChange={handleProductChange}
              />
              <div className="priceCalculationSpinner">
                {isCalculatingPrice ? (
                  <Spin tip="Calculating price..." />
                ) : (
                  <EditPrice
                    isReadOnly={isReadOnly()}
                    priceValue={price}
                    onPriceChange={handlePriceChange}
                    quantity={product.quantity}
                    allowMany={isQuantityBased}
                  />
                )}
              </div>

              {record.invoicingReference ? (
                <Typography>
                  <Divider />
                  <Title level={5}>{translate("EditInvoice_Reference")}</Title>
                  <Paragraph italic>{record.invoicingReference}</Paragraph>
                </Typography>
              ) : (
                ""
              )}
            </div>
          ) : (
            <Empty />
          )
        ) : (
          <div
            style={{
              marginTop: "100px",
              marginBottom: "100px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </Modal>
    </>
  );
}
