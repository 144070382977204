import axios from './axiosClient'

const invoiceApi = {
    getList: (params) => {
        const url = '/Invoicing/list'
        return axios.get(url, { params })
    },
    getSelectedCount: (until) => {
        const url = '/Invoicing/count'
        var params = {
            to: until,
        };
        return axios.get(url, { params });
    },

    putToBeInvoice: (id) => {
        const url = `/Invoicing/to-be-invoiced/${id}`
        return axios.put(url)
    },

    putFlag: (id) => {
        const url = `/Invoicing/flag/${id}`
        return axios.put(url)
    },
    putOpen: (id) => {
        const url = `/Invoicing/open/${id}`
        return axios.put(url)
    },
    putRemove: (id) => {
        const url = `/Invoicing/remove/${id}`
        return axios.put(url)
    },
    downloadBatch: (batchId) => {
        const url = `/Invoicing/download/${batchId}/csv`
        return axios.get(url, { responseType: 'blob' })
    },
    downloadPreviewBatch: (params) => {
        const url = `/Invoicing/download/preview/csv`
        return axios.get(url, { params, responseType: 'blob' })
    },
    createBatch: (params) => {
        const url = '/Invoicing/invoice'
        return axios.post(url,null, { params })
    },
    setInvoicePrice: (id, price) => {
        const url = `/Invoicing/item/${id}/price`
        return axios.put(url, { ...price })
    },
    setInvoiceProduct: (id, productId) => {
        const url = `/Invoicing/item/${id}/product/${productId}`
        return axios.put(url)
    },
    setInvoiceQuantity: (id, quantity) => {
        const url = `/Invoicing/item/${id}/quantity`
        return axios.put(url, { quantity: quantity, unit: "Unit" })
    },
    setInvoiceWeight: (id, weight) => {
        const url = `/Invoicing/item/${id}/quantity`
        return axios.put(url, { quantity: weight, unit: "WeightInKg" })
    },
    setInvoicingCustomer: (id, customerId) => {
        const url = `/Invoicing/item/${id}/invoicing-customer/${customerId}`
        return axios.put(url)
    },
    getInvoiceItem: (id) => {
        const url = `/Invoicing/item/${id}`
        return axios.get(url)
    },

}

export default invoiceApi;